<template>
  <Dialog v-model:visible="showing" :style="{ width: (renamingWindowOpen || settingBiosampleMetadataColumns) ? '850px' : '650px' }" :header="getHeader()" :modal="true" class="p-fluid" :closable="false">
    <div v-if="!expandedBiosampleSelectionOpened">
      <div v-if="!creating">
        <div v-if="!this.$store.state.addingNewBiosamples">
          <div v-if="!settingBiosampleMetadataColumns">
            <Message severity="error" v-if="this.inputFailed">Invalid input!</Message>
            <div class="field pt-4">
              <span class="p-float-label">
                <InputText id="projectName" type="text" :class="(validateString(projectName) === false) ? 'p-invalid' : ''" v-model.trim="projectName" required="true" autofocus />
                <label for="projectName">Project Name</label>
              </span>
              <InlineMessage :style="(validateString(projectName) === false) ? '' : 'display: none;'">Project name can only contain letters and numbers!</InlineMessage>
              <InlineMessage :style="projectNameAllreadyExists ? '' : 'display: none;'">That project name already exists!</InlineMessage>
            </div>
            <div class="field pt-4">
              <div class="p-inputgroup">
                <span class="p-float-label">
                  <Dropdown id="lotId" v-model="lotId" :options="this.getLotIds" editable optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" :filter="true" />
                  <!-- <InputText id="lotId" type="text" :class="((validateLotId(lotId) === false) ? 'p-invalid' : '') + ' lot-id-input' " v-model.trim="lotId" required="true" /> -->
                  <Button icon="pi pi-image" class="p-button-info" @click="toggleLotIdImage()" v-tooltip.right="'Show lot id location'" />
                  <label for="lotId">BioSkryb Product Lot ID</label>
                </span>
              </div>
              <InlineMessage :style="(validateLotId(lotId) === false) ? '' : 'display: none;'">This is not a valid Lot Id!</InlineMessage>
            </div>
            <div class="text-center" v-if="showLotIdImage">
              <Image
                :src="require('@/assets/LotId.png')"
                alt="LotId image"
                width="250"
                preview />
            </div>
            <div class="field pt-4">
              <span class="p-float-label">
                <Dropdown id="chemistryType" v-model="chemistryType" :options="avaialableChemistryTypes" />
                <label for="preparationType" class="mb-3">Sequencing Library preparation </label>
              </span>
            </div>
            <div class="field pt-4">
              <span class="p-float-label">
                <Textarea id="description" v-model="description" :style="{ overflow: 'auto' }" :class="(validateString(description) === false) ? 'p-invalid' : ''" :autoResize="true" rows="5" cols="30" />
                <label for="description" class="mb-3">Description</label>
              </span>
            </div>
            <div v-if="this.$store.state.bioskrybProduct === 'ResolveOME'" class="field">
              <label for="projectTypeRadio" class="mb-3">Project Type </label>
              <div id="projectTypeRadio" class="field-radiobutton d-flex justify-content-evenly">
                <div>
                  <RadioButton id="projectTypeDNA" name='projectTypeDNA' value="DNA" v-model="projectType" />
                  <label class="rb-label" for="projectTypeDNA">DNA</label>
                </div>
                <div>
                  <RadioButton id="projectTypeRNA" name='projectTypeRNA' value="RNA" v-model="projectType" />
                  <label class="rb-label" for="projectTypeRNA">RNA</label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="settingBiosampleMetadataColumns">
            <BiosampleMetadataColumnsDynamicRenderer v-model:biosampleMetadataColumns="biosampleMetadataColumns" />
          </div>
        </div>
        <div v-if="!settingBiosampleMetadataColumns">
          <div v-if="$store.state.precedenceLevel === 1">
            <div class="flex align-items-center">
              <InputSwitch class="check mr-3 mb-2" id="mergeFastqsCheck" v-model="mergeDuplicateFastqs" />
              <label class="lbl-check mb-2" for="mergeFastqsCheck">Merge duplicate fastqs</label>
            </div>
          </div>
          <div class="pt-1">
            <Button id="expandBSViewBtn" class="p-button mb-3" label="Select Biosamples" @click="toggleExpandedBiosampleTable()" />
            <Button class="mb-3" label="Add Biosample Metadata Columns" @click="openSettingBiosampleMetadataColumns" outlined />
            <div v-if="cachingBsshData">
              <span class="flex justify-content-center pt-3" v-if="displayWaitingOnBSSHMessage"><b>Caching BSSH Data. Please don't refresh the page!</b></span>
            </div>
          </div>
        </div>
      </div>
      <div class="creating-spinner-wrapper" v-else>
        <h3 class="field flex justify-content-center pb-3">Creating your project. Please wait.</h3>
        <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
      </div>
    </div>
    <div v-if="expandedBiosampleSelectionOpened">
      <div v-if="!renamingWindowOpen">

        <div class="pt-2 pb-2">
          <SelectButton v-model="pullingBiosamplesFrom" :options="biosamplePullingOptions" />
        </div>
        <div v-if="pullingBiosamplesFrom === 'BSSH'">
          <div v-if="loadingBSSHData" class="field flex flex-column justify-content-center container pt-4">
            <div v-if="!bsshFetchFailed">
              <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
              <span class="flex justify-content-center pt-3" v-if="displayWaitingOnBSSHMessage"><b>Pulling BaseSpace Sequence Hub Data</b></span>
            </div>
            <div v-if="bsshFetchFailed">
              <span class="flex justify-content-center pt-3">
                <b>Getting the biosamples from BaseSpace Sequence Hub has failed.</b>
              </span>
            </div>
            <div v-if="noBsshToken">
              <span class="flex justify-content-center pt-3">
                <b>No BaseSpace Token was found. Please contact your administrator</b>
              </span>
            </div>
          </div>
          <div v-if="!loadingBSSHData">
            <div class="pt-3 pb-1 flex">
              <div class="first-box">
                <div class="box-text">
                  <div>
                    Biosamples found: {{ biosamples.length }}
                  </div>
                  <div>
                    Biosamples selected: {{ selectedBiosamples.length }}
                  </div>
                </div>
              </div>
            <!-- <div class="second-box">
              <div class="box-text">
                <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</span>
              </div>
            </div> -->
            </div>
            <div class="p-inputgroup mb-4 mt-3">
              <Button v-if="this.selectedProjects.length > 0" icon="pi pi-angle-double-down" class="p-button-info addAllProjectButton" @click="addAllBiosamplesForProjects()" v-tooltip.left="'Add all biosamples from selected projects'" />
              <AutoComplete
                :multiple="true"
                v-model="selectedProjects"
                :suggestions="filteredProjects"
                @complete="searchProjects($event)"
                field="name"
                :virtualScrollerOptions="{ itemSize: 31 }"
                :placeholder="(this.selectedProjects.length === 0) ? 'BaseSpace Sequence Hub Project Name' : ''"
                dropdown
                forceSelection
              />
            </div>
            <DataTable
              :value="availableBiosamples"
              v-model:selection="selectedBiosamples"
              dataKey="id"
              :paginator="true"
              :rows="rows"
              v-model:filters="bsshTableFilters"
              ref="dt"
              filterDisplay="menu"
              :globalFilterFields="['bioSampleName']"
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :rowsPerPageOptions="[5, 10, 20, 50]">
              <Column selectionMode="multiple" />
              <Column field="bioSampleName" header="Biosample Name" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="bioSampleName" :sortable="true">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="Search by Biosample Name" />
                </template>
                <template #loadingBSSHData>
                  <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-if="pullingBiosamplesFrom === 'Shared Data'">
          <div v-if="loadingS3Data" class="field flex flex-column justify-content-center container pt-4">
            <div v-if="!S3FetchFailed">
              <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
              <span class="flex justify-content-center pt-3" v-if="displayWaitingOnBSSHMessage"><b>Waiting on Shared Data</b></span>
            </div>
            <div v-if="S3FetchFailed">
              <span class="flex justify-content-center pt-3">
                <b>Getting the biosamples from Shared Data has failed.</b>
              </span>
            </div>
            <div v-if="noS3Path">
              <span class="flex justify-content-center pt-3">
                <b>No S3 shared path was found. Please contact your administrator</b>
              </span>
            </div>
          </div>
          <div v-if="!loadingS3Data">
            <div class="pt-3 pb-1 flex">
              <div class="first-box">
                <div class="box-text">
                  <div>
                    Biosamples found: {{ Object.values(sampleToFastqPairs).length }}
                  </div>
                  <div>
                    Biosamples selected: {{ selectedS3Biosamples.length }}
                  </div>
                </div>
              </div>
              <div class="second-box" v-if="Object.values(badSamplesMap).length > 0">
                <div class="box-text">
                  <div>
                    <span style="color: red;"><b>WARNING: Incorrectly named biosamples.</b></span>
                  </div>
                  <div class="flex justify-content-end pt-2">
                    <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                    <a href="https://docs.basejumper.bioskryb.com/getting-started/data-import/data-import/#file-naming-requirements" target="_blank" rel="noopener noreferrer">
                      <Button icon="pi pi-question" rounded aria-label="Filter" />
                    </a>
                    <Button class="correct-btn" label="Correct" rounded @click="renamingWindowOpen = true" />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              :value="Object.values(sampleToFastqPairs)"
              v-model:selection="selectedS3Biosamples"
              dataKey="sampleId"
              :paginator="true"
              :rows="rows"
              v-model:filters="s3TableFilters"
              ref="dt2"
              filterDisplay="menu"
              :globalFilterFields="['sampleId']"
              responsiveLayout="scroll"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :rowsPerPageOptions="[5, 10, 20, 50]">
              <Column selectionMode="multiple" headerStyle="width: 3em" />
              <Column field="sampleId" header="Biosample Name" headerStyle="text-align: center" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="sampleId" :sortable="true">
                <template #filter="{ filterModel }">
                  <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by biosample name" />
                </template>
                <template #loadingS3Data>
                  <div class="flex align-items-center" :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                    <Skeleton width="60%" height="1rem" />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
      <div v-if="renamingWindowOpen">
        <div class="sample-name-notice pb-3">
          <span>Remember, files must be named like <b>&lt;sample-name&gt;_S1_L001_R1_001.fastq.gz</b> and <b>&lt;sample-name&gt;_S1_L001_R2_001.fastq.gz</b>.</span>
          <span>Please see the <a href="https://docs.basejumper.bioskryb.com/getting-started/data-import/data-import/#file-naming-requirements" target="_blank" rel="noopener noreferrer">User Manual</a> for more details.</span>
        </div>
        <DataTable
          :value="Object.values(badSamplesMap)"
          dataKey="sampleName"
          @filter="onRenameTableFilter($event)"
          :paginator="true"
          :rows="rows"
          v-model:filters="renameTableFilters"
          ref="dt3"
          filterDisplay="menu"
          :globalFilterFields="['sampleName']"
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          :rowsPerPageOptions="[5, 10, 20, 50]"
          editMode="cell"
          @cell-edit-complete="onCellEditComplete"
          exportFilename="BiosampleRenaming">
          <template #header>
            <div class="export-btn">
              <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
            </div>
          </template>
          <!-- <Column selectionMode="multiple" headerStyle="width: 3em" /> -->
          <Column field="sampleName" header="Original Name" headerStyle="text-align: center; width: 50%" :bodyStyle="getBodyStyle()" filterMatchMode="startsWith" ref="sampleName" :sortable="true">
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by biosample name" />
            </template>
          </Column>
          <Column field="newName" header="New Name" headerStyle="text-align: center; width: 50%" :bodyStyle="getBodyStyle()" ref="newName">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" autofocus />
            </template>
            <template #body="{ data }">
              <span :class="(illuminaStandardRegex.test(data.newName)) ? '' : 'bad'">{{ data.newName }}</span>
            </template>
          </Column>
        </DataTable>
        <div class="rename-footer-options">
          <div class="flex align-items-center pt-3">
            <InputSwitch class="check mr-3 mb-2" id="renameGoodFiles" v-model="renameOnlyBadFiles" />
            <label class="lbl-check mb-2" for="renameGoodFiles">Only show files with incorrect names</label>
          </div>
          <div class="flex">
            <div class="processing-rename-spinner" v-if="processingRenameFile">
              <img alt="BJSpinner" class="bjSpinner" src="@/assets/BioSkrybElements/BaseJumber-BackgroundMarkCroped.png" />
            </div>
            <FileUpload class="mr-3" v-if="!processingRenameFile" mode="basic" name="renameUpload[]" :maxFileSize="1000000" customUpload @uploader="onUpload" :auto="true" chooseLabel="Browse" />
            <Button class="aut-rename-btn" label="Attempt auto rename" @click="attemptAutoRename" />
          </div>
        </div>
      </div>
    </div>
    <template #footer v-if="!creating">
      <div class="pt-4">
        <Button v-if="expandedBiosampleSelectionOpened && !renamingWindowOpen" class="p-button-secondary mr-2" label="Close selection" @click="toggleExpandedBiosampleTable()" />
        <Button label="Cancel" icon="pi pi-times" class="p-button-secondary mr-2" :disabled="creating" @click="hideDialog" />
        <Button v-if="settingBiosampleMetadataColumns" label="Back" icon="pi" class="p-button-secondary" @click="settingBiosampleMetadataColumns = false" />
        <Button v-if="!renamingWindowOpen" :label="($store.state.addingNewBiosamples) ? 'Add Biosamples' : 'Create Project'" class="p-button-outline" :disabled="creating || disableCreatingBecauseOfLoading || (validateString(projectName) === false) || noBiosamplesSelected() || (selectedBiosamples.length === 0 && selectedS3Biosamples.length === 0)" @click="createProject" />
        <Button v-if="renamingWindowOpen" label="Done" @click="doneRenaming" />
      </div>
    </template>
  </Dialog>
  <ImmediatePipelineLaunch
    :projectForPipeline="newProject"
    @launchPipelines="runS3BucketPipeline" />
</template>

<script>
// The main part about the component are the biosamples. The list of all biosamples is stored in the Illumina's BaseSpace Sequence Hub(BSSH) and must be pulled with their API. This populate the two Autocomplete components (project and biosamples). A BSSH project contains multiple biosamples so we can filter the biosample field by the project field (show only biosamples which are in the specific project). Once the biosamples are selected, launch and AWS Event Bridge message and put in on the SNS Queue. This will go to the Base Jumper Runner which will start the process of downloading the biosamples from the BSSH to our Shared Data bucket. The BSSH has been known to bug out but we cant do anything about that expect show a 5 second toast message to the user that its not our fault.
import axios from 'axios';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/custom_queries';
import {
  // eslint-disable-next-line no-unused-vars
  wait, removeDuplicatesById, getToday, listItems, sendEBMessage, validateString, validateLotId, makeBiosampleMetadataColumnsJson, duplicatesByKeyInArray, valueIsNullOrUndefined, isNumber,
} from '@/utils';
import constants from '@/assets/constants.json';
import ImmediatePipelineLaunch from '@/components/Pipeline/ImmediatePipelineLaunch.vue';
import BiosampleMetadataColumnsDynamicRenderer from '@/components/Project/BiosampleMetadataColumnsDynamicRenderer.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import _ from 'lodash';

const asyncBatch = require('async-batch').default;

export default {
  name: 'NewProjectDialog',
  emits: ['addProjectToTable'],
  components: {
    ImmediatePipelineLaunch,
    BiosampleMetadataColumnsDynamicRenderer,
  },
  data() {
    return {
      projects: [],
      biosamples: [], // Contains biosamples from BSSH
      sampleToFastqPairs: {}, // Contains the fastq files for each biosample from shared data
      pullingBiosamplesFrom: 'BSSH',
      creating: false,
      loadingBSSHData: false,
      loadingS3Data: false,
      renameOnlyBadFiles: true,
      renamingWindowOpen: false,
      displayWaitingOnBSSHMessage: false,
      expandedBiosampleSelectionOpened: false,
      settingBiosampleMetadataColumns: false,
      showing: false,
      bsshFetchFailed: false,
      S3FetchFailed: false,
      projectNameAllreadyExists: false,
      inputFailed: false,
      showLotIdImage: false,
      cachingBsshData: false,
      mergeDuplicateFastqs: false,
      processingRenameFile: false,
      description: '',
      projectName: '',
      chemistryType: '',
      lotId: '',
      projectType: 'DNA',
      // illuminaStandardRegex: /(.*)(_S\d+_L\d+)/,
      illuminaStandardRegex: /(.*)(_S\d+_L\d+_R[12]_001.fastq.gz)/,
      // availableLotIds: [],
      avaialableChemistryTypes: constants.avaialableChemistryTypes,
      preparationType: null,
      availableBiosamples: [],
      badSamplesMap: {},
      badSamplesMapSaved: {}, // Duplicate of badSamplesMapSaved in case the user wants to rename both good and bad files
      filteredRenameBiosamples: [],
      selectedBiosamples: [],
      filteredBiosamples: [],
      numberOfCreatedBiosamples: 0,
      selectedProjects: [],
      selectedS3Biosamples: [],
      listOfS3Files: [],
      filteredS3Biosamples: [],
      availableProjects: [],
      filteredProjects: [],
      biosampleMetadataColumns: [
        {
          name: '',
          type: '',
          description: '',
        },
      ],
      noBsshToken: false,
      noS3Path: false,
      newProject: null, // Saving the new project for makeBSSHEntries(), there is probably a better way
      workspace: null,
      expandedBSSHBiosampleSelectionTable: false,
      expandedS3BiosampleSelectionTable: false,
      bsshTableFilters: {
        bioSampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      },
      s3TableFilters: {
        bioSampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      },
      renameTableFilters: {
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      },
      biosamplePullingOptions: ['BSSH', 'Shared Data'],
      rows: 5,
    };
  },
  methods: {
    async main() {
      this.resetVariables();
      this.loadingBSSHData = true;
      this.loadingS3Data = true;
      this.showWaitingOnBSSHMessageAfterFiveSeconds();
      this.workspace = await this.getWorkspace();
      if (this.workspace === null || this.workspace === undefined) {
        throw new Error('Workspace is null');
      }
      this.loadProjects();
      this.tryToGetBSSHBiosamples();
      this.tryToGetS3Biosamples();
    },
    async showWaitingOnBSSHMessageAfterFiveSeconds() {
      setTimeout(() => { this.displayWaitingOnBSSHMessage = true; }, 5000);
    },
    async loadProjects() {
      const workspaceAtStart = this.workspace;
      const projects = await listItems(customQueries.projectsByWorkspaceNames, {
        limit: 500,
        workspaceId: workspaceAtStart.id,
        filter: {
          assayName: { eq: this.$store.state.bioskrybProduct },
        },
      });
      if (workspaceAtStart === this.workspace) {
        this.projects = projects;
      }
    },
    tryToGetBSSHBiosamples() {
      if (this.workspace.basespaceAccessToken !== null && this.workspace.basespaceAccessToken !== undefined) {
        this.getAllBSSHBiosamplesNew();
      } else {
        this.noBsshToken = true;
        this.biosamples = [];
        this.availableBiosamples = [];
        this.filteredBiosamples = [];
      }
    },
    tryToGetS3Biosamples() {
      if (this.workspace.sharedS3Paths !== null && this.workspace.sharedS3Paths !== undefined && this.workspace.sharedS3Paths.length > 0) {
        this.getAllS3Biosamples();
      } else {
        this.noS3Path = true;
        this.selectedS3Biosamples = [];
        this.filteredS3Biosamples = [];
      }
    },
    async createProject() {
      if (this.noBiosamplesSelected()) {
        this.$toast.add({
          severity: 'warn', summary: 'No biosamples selected.', detail: 'In order to create a project you must select biosamples.', life: 5000,
        });
        return;
      }
      if (this.$store.state.addingNewBiosamples) {
        this.addingNewBiosamplesWorkflow();
        return;
      }
      for (let i = 0; i < this.projects.length; i += 1) {
        if (this.projects[i].clientProjectName === this.projectName) {
          this.projectNameAllreadyExists = true;
          return;
        }
      }
      if ((this.validateString(this.projectName) === false)) {
        this.inputFailed = true;
        return;
      }
      if (duplicatesByKeyInArray(this.biosampleMetadataColumns, 'name')) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Column name already exists!',
          life: 3000,
        });
        return;
      }
      this.expandedBiosampleSelectionOpened = false;
      this.expandedBSSHBiosampleSelectionTable = false;
      this.expandedS3BiosampleSelectionTable = false;
      let initiator = (await Auth.currentAuthenticatedUser()).attributes.email;
      if (initiator === undefined || initiator === null) {
        initiator = 'Unknown';
      }
      this.creating = true;
      this.expandedBSSHBiosampleSelectionTable = false;
      this.expandedS3BiosampleSelectionTable = false;
      const projectVariables = {
        clientProjectName: this.projectName,
        assayName: this.$store.state.bioskrybProduct,
        analysisType: this.chemistryType,
        projectType: this.projectType,
        lotId: this.unpackLotId(this.lotId),
        workspaceProjectsId: this.$store.state.activeWorkspace,
        biosampleMetadataColumns: makeBiosampleMetadataColumnsJson(this.biosampleMetadataColumns),
        size: 0,
        description: this.description,
        workspaceId: this.$store.state.activeWorkspace,
        status: 'Submitted',
        initiator,
        adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
        readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
      };
      // First create the project entity in the database
      try {
        const { data } = await API.graphql(graphqlOperation(mutations.createProject, { input: projectVariables }));
        let biosamplesVariablesArr = [];
        const biosamplesVariablesBucketArr = [];
        if (this.pullingBiosamplesFrom === 'BSSH') {
          for (let i = 0; i < this.selectedBiosamples.length; i += 1) {
            const biosampleVariables = {
              biosampleName: this.selectedBiosamples[i].bioSampleName,
              projectId: data.createProject.id,
              lotId: this.unpackLotId(this.lotId),
              projectBiosamplesId: data.createProject.id,
              // projectBiosamplesId: this.selectedBiosamples[i].Id,
              bsshId: this.selectedBiosamples[i].id,
              adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
            };
            biosamplesVariablesArr.push(biosampleVariables);
            if (biosamplesVariablesArr.length === 25) {
              biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
              biosamplesVariablesArr = [];
            }
          }
        } else if (this.pullingBiosamplesFrom === 'Shared Data') {
          if (this.selectedS3Biosamples === null) {
            console.log('Is null');
          }
          for (let i = 0; i < this.selectedS3Biosamples.length; i += 1) {
            const biosampleVariables = {
              biosampleName: this.selectedS3Biosamples[i].sampleId,
              projectId: data.createProject.id,
              lotId: this.unpackLotId(this.lotId),
              projectBiosamplesId: data.createProject.id,
              fastqs: this.selectedS3Biosamples[i].fastqs,
              adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
            };
            biosamplesVariablesArr.push(biosampleVariables);
            if (biosamplesVariablesArr.length === 25) {
              biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
              biosamplesVariablesArr = [];
            }
          }
        }
        if (biosamplesVariablesArr.length > 0) biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
        const allBiosamples = await asyncBatch(biosamplesVariablesBucketArr, this.createBatchBiosample, 50);
        this.newProject = data.createProject;
        this.$emit('addProjectToTable', this.newProject);
        this.newProject.biosamples = allBiosamples.flat();
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Project created successfully!',
          life: 3000,
        });
        this.ImmediatePipelineLaunchShow();
        // this.$store.dispatch('showNewProject');
        // this.hideDialog();
        this.creating = false;
      } catch (error) {
        console.log('project creation error - ', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Creating project failed. Please try again.',
          life: 3000,
        });
        this.creating = false;
      }
    },
    async addingNewBiosamplesWorkflow() {
      console.log('Adding new biosamples workflow');
      try {
        const biosamplesVariablesBucketArr = [];
        let biosamplesVariablesArr = [];
        if (this.pullingBiosamplesFrom === 'BSSH') {
          for (let i = 0; i < this.selectedBiosamples.length; i += 1) {
            const biosampleVariables = {
              biosampleName: this.selectedBiosamples[i].bioSampleName,
              projectId: this.$store.state.selectedProject.id,
              lotId: this.$store.state.selectedProject.lotId,
              projectBiosamplesId: this.$store.state.selectedProject.id,
              // projectBiosamplesId: this.selectedBiosamples[i].Id,
              bsshId: this.selectedBiosamples[i].id,
              adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
            };
            biosamplesVariablesArr.push(biosampleVariables);
            if (biosamplesVariablesArr.length === 25) {
              biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
              biosamplesVariablesArr = [];
            }
          }
        } else if (this.pullingBiosamplesFrom === 'Shared Data') {
          if (this.selectedS3Biosamples === null) {
            console.log('Is null');
          }
          for (let i = 0; i < this.selectedS3Biosamples.length; i += 1) {
            const biosampleVariables = {
              biosampleName: this.selectedS3Biosamples[i].sampleId,
              projectId: this.$store.state.selectedProject.id,
              lotId: this.$store.state.selectedProject.lotId,
              projectBiosamplesId: this.$store.state.selectedProject.id,
              // r1FastqName: this.selectedS3Biosamples[i].fastq1.split('/').at(-1),
              // r1FastqS3Uri: this.selectedS3Biosamples[i].fastq1,
              // r2FastqName: this.selectedS3Biosamples[i].fastq2.split('/').at(-1),
              // r2FastqS3Uri: this.selectedS3Biosamples[i].fastq2,
              fastqs: this.selectedS3Biosamples[i].fastqs,
              adminGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              writeGroups: [`WS/${this.$store.state.activeWorkspace}/Admin`, `ORG/${this.$store.state.activeOrganization}/Admin`],
              readGroups: [`WS/${this.$store.state.activeWorkspace}/User`, `ORG/${this.$store.state.activeOrganization}/User`],
            };
            biosamplesVariablesArr.push(biosampleVariables);
            if (biosamplesVariablesArr.length === 25) {
              biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
              biosamplesVariablesArr = [];
            }
          }
        }
        if (biosamplesVariablesArr.length > 0) biosamplesVariablesBucketArr.push([...biosamplesVariablesArr]);
        const allBiosamples = await asyncBatch(biosamplesVariablesBucketArr, this.createBatchBiosample, 50);
        this.projectName = this.$store.state.selectedProject.clientProjectName;
        this.newProject = this.$store.state.selectedProject;
        this.newProject.biosamples.items = allBiosamples.flat();
        const details = await this.getDetails();
        this.runS3BucketPipeline([], details);
        this.hideDialog();
      } catch (error) {
        console.error('Error in addingNewBiosamplesWorkflow');
        console.error(error);
      }
    },
    hideDialog() {
      this.projectName = '';
      this.lotId = '';
      this.chemistryType = '';
      this.description = '';
      this.selectedBiosamples = [];
      this.selectedS3Biosamples = [];
      this.selectedProjects = [];
      this.renamingWindowOpen = false;
      this.newProject = null;
      this.resetFilters();
      if (this.expandedBSSHBiosampleSelectionTable) this.toggleExpandedBSSHBiosampleTable();
      if (this.expandedS3BiosampleSelectionTable) this.toggleExpandedS3BiosampleTable();
      if (this.$store.state.addingNewBiosamples) {
        this.$store.dispatch('setAddingNewBiosamples', false);
        this.$store.dispatch('setSelectedProject', null);
      }
      // this.$store.dispatch('showNewProject');
      this.$store.dispatch('setShowNewProject', false);
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    async createBiosample(biosampleVariables) {
      try {
        const res = await API.graphql(graphqlOperation(mutations.createBiosample, { input: biosampleVariables }));
        this.numberOfCreatedBiosamples += 1;
        return res.data.createBiosample;
      } catch (error) {
        console.error('Error creating biosample with biosample variables: ');
        console.error(biosampleVariables);
        console.error(error);
      }
      return null;
    },
    async createBatchBiosample(biosampleVariablesArr) {
      try {
        const res = await API.graphql(graphqlOperation(mutations.createBatchBiosamples, { input: biosampleVariablesArr }));
        this.numberOfCreatedBiosamples += 1;
        if (res.data.createBatchBiosamples.unprocessedItems !== null && res.data.createBatchBiosamples.unprocessedItems !== undefined && res.data.createBatchBiosamples.unprocessedItems.length > 0) console.error('Some biosamples were not created');
        return res.data.createBatchBiosamples.processedItems;
      } catch (error) {
        console.error('Error creating biosample with biosample variables: ');
        console.error(biosampleVariablesArr);
        console.error(error);
      }
      return null;
    },
    async getWorkspace() {
      const response = await API.graphql(graphqlOperation(queries.getWorkspace, { limit: 300, id: this.$store.state.activeWorkspace }));
      return response.data.getWorkspace;
    },
    // Send an event message to run the download process.
    // eslint-disable-next-line no-unused-vars
    async runS3BucketPipeline(pipelinesToLaunch = [], parsedDetails = null) {
      // eslint-disable-next-line no-unused-vars
      let details = null;
      if (parsedDetails === null) {
        details = await this.getDetails(pipelinesToLaunch);
      } else {
        details = parsedDetails;
      }
      sendEBMessage(details, details.organization_uuid, details.workspace_uuid, details.project_name_uuid, 'project');
      this.projectName = '';
      this.lotId = '';
      this.chemistryType = '';
      this.selectedBiosamples = [];
      this.selectedS3Biosamples = [];
      this.selectedProjects = [];
      this.newProject = null;
    },
    async getDetails(pipelinesToLaunch) {
      if (pipelinesToLaunch === null || pipelinesToLaunch === undefined) {
        // eslint-disable-next-line no-param-reassign
        pipelinesToLaunch = [];
      }
      const details = {
        status: 'KickoffPipelines',
        pipeline: (this.pullingBiosamplesFrom === 'BSSH') ? 'move_biosamples_from_bssh_to_s3' : 'move_biosamples_from_internal_s3',
        step: 'pipeline_trigger',
        application_environment: process.env.VUE_APP_SLS_STAGE,
        // project_name: this.projectName,
        project_name: this.newProject.clientProjectName,
        project_name_uuid: this.newProject.id,
        workspace_name: this.workspace.description,
        workspace_uuid: this.$store.state.activeWorkspace,
        organization_name: this.workspace.organization.organizationName,
        organization_uuid: this.workspace.organizationId,
        updating_existing_project: this.$store.state.addingNewBiosamples,
        pipelines_to_trigger: pipelinesToLaunch,
        bssh_token: this.workspace.basespaceAccessToken,
        merge_duplicated_fastq_files: this.mergeDuplicateFastqs,
        entries: (this.pullingBiosamplesFrom === 'BSSH') ? this.makeBSSHEntries(this.workspace.organizationId) : this.makeS3Entries(this.workspace.organizationId),
      };
      return details;
    },
    makeS3Entries(organizationId) {
      try {
        let projectBiosamples = [];
        if ('items' in this.newProject.biosamples) {
          projectBiosamples = this.newProject.biosamples.items;
        } else {
          projectBiosamples = this.newProject.biosamples;
        }
        const entries = projectBiosamples.map((biosample) => {
          console.log('this.sampleToFastqPairs[biosample.biosampleName] :>> ', this.sampleToFastqPairs[biosample.biosampleName]);
          const entrie = {
            bucket: process.env.VUE_APP_BUCKET,
            organization_uuid: organizationId,
            workspace_uuid: this.$store.state.activeWorkspace,
            project_name_uuid: this.newProject.id,
            biosample_uuid: biosample.id,
            biosample_name: biosample.biosampleName,
            biosample_id: biosample.sampleId,
            fastqs: [...new Set([...biosample.fastqs])],
            ...(('fastqs_old_to_new_names' in this.sampleToFastqPairs[biosample.biosampleName]) && { fastqs_old_to_new_names: this.sampleToFastqPairs[biosample.biosampleName].fastqs_old_to_new_names }),
            species_name: 'human',
          };
          console.log('entrie :>> ', entrie);
          return entrie;
        });
        return entries;
      } catch (error) {
        console.error('Error making Shared Data entries: ');
        console.error(error);
        return [];
      }
    },
    makeBSSHEntries(organizationId) {
      let projectBiosamples = [];
      if ('items' in this.newProject.biosamples) {
        projectBiosamples = this.newProject.biosamples.items;
      } else {
        projectBiosamples = this.newProject.biosamples;
      }
      const entries = projectBiosamples.map((biosample) => {
        const entrie = {
          bucket: process.env.VUE_APP_BUCKET,
          organization_uuid: organizationId,
          workspace_uuid: this.$store.state.activeWorkspace,
          project_name_uuid: this.newProject.id,
          biosample_uuid: biosample.id,
          biosample_name: biosample.biosampleName,
          biosample_id: biosample.bsshId,
          species_name: 'human',
        };
        return entrie;
      });
      return entries;
    },
    // The project is just an attribute of the biosample, so we need to map it out and create our own list of projects.
    async makeProjects() {
      console.log('Started making project');
      const projects = [];
      this.biosamples.forEach((biosample) => {
        // const found = projects.some((el) => el.name === biosample.DefaultProject.Name);
        // console.log('biosample :>> ', biosample);
        try {
          const found = projects.some((el) => el.name === biosample.defaultProject);
          if (!(found)) {
            const project = { name: biosample.defaultProject };
            projects.push(project);
          }
        } catch (error) {
          console.error('Error making projects: ');
          console.error(error);
        }
      });
      console.log('projects :>> ', projects);
      return projects;
    },
    searchProjects(event) {
      const options = [];
      for (let i = 0; i < this.availableProjects.length; i += 1) {
        if (this.availableProjects[i].name.includes(event.query)) {
          options.push(this.availableProjects[i]);
        }
      }
      this.filteredProjects = options;
    },
    // Get a single page of 2000 biosamples. Each call can fail if the timeout is exceeded.
    async getBiosamples(offset, sortDir, limit = 2000) {
      // console.log('Getting biosamples for page: ', page);
      try {
        // const url = `https://api.basespace.illumina.com/v2/biosamples/?Offset=${offset}&Limit=2000`;
        const url = `https://api.basespace.illumina.com/v2/biosamples?limit=${limit}&sortby=DateModified&sortDir=${sortDir}&offset=${offset}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-access-token': this.workspace.basespaceAccessToken,
          },
          redirect: 'follow',
          mode: 'cors',
          cache: 'no-cache',
          referrerPolicy: 'no-referrer',
          timeout: 20000,
        });
        const samplesWithDefaultProject = this.filterBSSHSamplesWithDefaultProject(response.data.Items);
        const removed = response.data.Items.length - samplesWithDefaultProject.length;
        const newTotalCount = response.data.Paging.TotalCount - removed;
        return {
          samples: samplesWithDefaultProject,
          total: newTotalCount,
          displayedCount: response.data.Paging.DisplayedCount,
        };
      } catch (error) {
        console.log(error);
        if (error.code === 'ECONNABORTED') {
          console.log('Timeout error');
          this.$toast.add({
            severity: 'error',
            summary: 'Biosamples timeout',
            detail: 'Getting biosamples from Illumina BaseSpace Sequence Hub took too long. Please try again later ',
            life: 5000,
          });
          this.bsshFetchFailed = true;
          return null;
        }
        console.log('Second error');
        this.$toast.add({
          severity: 'error',
          summary: 'Biosamples Error',
          detail: 'There was a problem with getting the Biosamples from Illumina BaseSpace Sequence Hub.',
          life: 5000,
        });
        this.bsshFetchFailed = true;
        return null;
      }
    },
    filterBSSHSamplesWithDefaultProject(bsshSamples) {
      return bsshSamples.filter((sample) => !valueIsNullOrUndefined(sample.DefaultProject));
    },
    async getAllS3Biosamples() {
      try {
        this.loadingS3Data = true;
        // const workspaceAtStart = this.workspace;
        const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
        try {
          const url = `https://importable-files.bioskryb.com/${process.env.VUE_APP_SLS_STAGE}/v1/imports?type=FastQ&workspace=${this.$store.state.activeWorkspace}`;
          const response = await axios.get(url, {
            headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${token}`,
            },
            redirect: 'follow',
            timeout: 20000,
          });
          this.listOfS3Files = response.data.files;
          this.listOfS3Files = this.listOfS3Files.filter((fileObj) => !fileObj.Path.S.includes('/export_data/')); // Without this filter, exported files will also be sent
          const biosampleMap = {};
          this.listOfS3Files.forEach((fullFileObj) => {
            const file = fullFileObj.Path.S.split('/').at(-1);
            const fullFilePath = `s3://bioskryb-data-share/${fullFileObj.Path.S}`;
            const test = this.illuminaStandardRegex.test(file);
            if (test) {
              const match = file.match(this.illuminaStandardRegex);
              const biosampleName = match[1];
              if (biosampleName in biosampleMap) {
                if (!('fastq2' in biosampleMap[biosampleName])) {
                  biosampleMap[biosampleName].fastq2 = fullFilePath;
                }
                biosampleMap[biosampleName].fastqs.push(fullFilePath);
              } else {
                biosampleMap[biosampleName] = {
                  sampleId: biosampleName,
                  fastqs: [fullFilePath],
                  fastq1: fullFilePath,
                };
              }
            } else {
              this.badSamplesMap[file] = {
                sampleName: file,
                fullFilePath,
                newName: '',
              };
            }
          });
          // eslint-disable-next-line consistent-return, array-callback-return
          const filteredForBothReads = Object.fromEntries(Object.entries(biosampleMap).filter(([key]) => {
            if (biosampleMap[key].fastqs.length >= 2) {
              return biosampleMap[key];
            }
          }));
          this.sampleToFastqPairs = filteredForBothReads;
          if (this.$store.state.addingNewBiosamples) {
            this.sampleToFastqPairs = Object.fromEntries(Object.entries(this.sampleToFastqPairs).filter((bs) => !this.$store.state.selectedProject.biosamples.items.some((projectBs) => projectBs.biosampleName === bs.sampleId)));
          }
          this.loadingS3Data = false;
        } catch (error) {
          console.error('error in call :>> ', error);
        }
      } catch (error) {
        console.error('Error getting all S3 biosamples');
        console.error(error);
        this.loadingS3Data = false;
        this.S3FetchFailed = true;
      }
    },
    addGoodSamplesForRenaming() {
      try {
        this.listOfS3Files.forEach((fullFileObj) => {
          const file = fullFileObj.Path.S.split('/').at(-1);
          const fullFilePath = `s3://bioskryb-data-share/${fullFileObj.Path.S}`;
          if (!(file in this.badSamplesMap)) {
            this.badSamplesMap[file] = {
              sampleName: file,
              fullFilePath,
              newName: file,
            };
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    renamedFiledPreProcessing() {
      try {
        console.log('process renamed files :>> ');
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * Get last 300 samples sorted by date modified
     * If the last sample has date modified bigger than the workspaces lastCached, pull more so we get all samples changed since the last cache
     * for biosample in lastChangedBiosamples:
     *    if biosample.dateModified > workspace.lastCached:
     *        {inCache, cachedBiosample} = isBiosampleInCache(biosample)
     *        if biosample.Project.size === 0 && inCache:
     *            removeFromCache(biosample)
     *        if inCache && biosample.DefaultProject !== cachedBiosample.DefaultProject
     *            updateBiosampleInCache(biosample)
     */
    async getAllBSSHBiosamplesNew() {
      try {
        const workspaceAtStart = this.workspace;
        const cachedBiosamplesPromise = this.getCachedBSSHBiosamples(workspaceAtStart);
        const initialLimit = 300;
        // eslint-disable-next-line no-unused-vars
        const cacheDate = getToday();
        let offset = initialLimit;
        if (!this.loadingBSSHData) this.loadingBSSHData = true;
        if (this.bsshFetchFailed) this.bsshFetchFailed = false;
        const firstGetSamples = await this.getBiosamples(0, 'desc', initialLimit);
        const latestChanged = firstGetSamples.samples;
        const totalInBSSH = firstGetSamples.total;
        let workspaceLastCachedDate = null;
        if (workspaceAtStart.lastCached === null || workspaceAtStart.lastCached === undefined) {
          workspaceLastCachedDate = new Date(1999, 1, 1);
        } else {
          workspaceLastCachedDate = new Date(workspaceAtStart.lastCached);
        }
        const lastChangedData = new Date(latestChanged.at(-1).DateModified);
        let moreChangedbiosamples = (lastChangedData > workspaceLastCachedDate);
        while (moreChangedbiosamples) {
          const moreBiosamplesQuery = await this.getBiosamples(offset, 'desc', 1000);
          const moreBSSHBiosamples = moreBiosamplesQuery.samples;
          latestChanged.push(...moreBSSHBiosamples);
          moreChangedbiosamples = (new Date(latestChanged.at(-1).DateModified) > workspaceLastCachedDate);
          offset += 1000;
          console.log(`Biosample query count: ${moreBiosamplesQuery.displayedCount}`);
          if (totalInBSSH === latestChanged.length || moreBiosamplesQuery.displayedCount === 0) break;
        }
        const cachedBiosamplesMap = await cachedBiosamplesPromise;
        if (cachedBiosamplesMap === null) {
          console.error('cachedBiosamplesMap is null');
          return;
        }
        let batch = [];
        const empty = [];
        for (let i = 0; i < latestChanged.length; i += 1) {
          if (batch.length >= 1000) {
            await this.runBatchCalls(batch);
            batch = [];
          }
          const bs = latestChanged[i];
          const bsDateModified = new Date(bs.DateModified);
          if (bsDateModified > workspaceLastCachedDate) {
            const inCache = cachedBiosamplesMap.has(bs.Id);
            const cachedBiosample = cachedBiosamplesMap.get(bs.Id);
            if (inCache) {
              if (bs.DefaultProject.TotalSize === 0) {
                console.log('Remove biosample :>>', bs.Id);
                try {
                  const deleteVariables = {
                    id: bs.Id,
                    workspaceId: workspaceAtStart.id,
                  };
                  batch.push({ func: this.deleteBSSHBiosample, args: [deleteVariables] });
                  cachedBiosamplesMap.delete(bs.Id);
                } catch (error) {
                  console.error('Error in remove biosample');
                  console.error(error);
                }
              } else {
                const updateParams = {
                  id: bs.Id,
                  workspaceId: workspaceAtStart.id,
                };
                let needsToBeUpdated = false;
                if (bs.DefaultProject.Name !== cachedBiosample.defaultProject) {
                  updateParams.defaultProject = bs.DefaultProject.Name;
                  needsToBeUpdated = true;
                }
                if (bs.BioSampleName !== cachedBiosample.bioSampleName) {
                  updateParams.bioSampleName = bs.BioSampleName;
                  needsToBeUpdated = true;
                }
                if (needsToBeUpdated) {
                  batch.push({ func: this.updateBSSHBiosample, args: [updateParams] });
                  const original = cachedBiosamplesMap.get(updateParams.id);
                  if ('defaultProject' in updateParams) {
                    original.defaultProject = updateParams.defaultProject;
                  }
                  if ('bioSampleName' in updateParams) {
                    original.bioSampleName = updateParams.bioSampleName;
                  }
                  cachedBiosamplesMap.set(updateParams.id, original);
                }
              }
            } else if (bs.DefaultProject.TotalSize > 0) {
              const readGroups = [`WS/${workspaceAtStart.id}/User`, `ORG/${workspaceAtStart.organizationId}/User`];
              const writeGroups = [`WS/${workspaceAtStart.id}/User`, `ORG/${workspaceAtStart.organizationId}/User`];
              const adminGroups = [`WS/${workspaceAtStart.id}/Admin`, `ORG/${workspaceAtStart.organizationId}/Admin`];
              const bsshBiosampleVariables = {
                id: bs.Id,
                bioSampleName: bs.BioSampleName,
                defaultProject: bs.DefaultProject.Name,
                workspaceId: workspaceAtStart.id,
                readGroups,
                writeGroups,
                adminGroups,
              };
              try {
                batch.push({ func: this.createBSSHBiosample, args: [bsshBiosampleVariables] });
                cachedBiosamplesMap.set(bsshBiosampleVariables.id, bsshBiosampleVariables);
              } catch (error) {
                console.error('Error in creating bssh biosample');
                console.error(error);
              }
              // console.log('bsshBiosampleVariables :>> ', bsshBiosampleVariables);
            } else {
              empty.push(bs.Id);
            }
          }
        }
        if (batch.length > 0) {
          await this.runBatchCalls(batch);
          batch = [];
        }
        const updateWSVariables = {
          id: workspaceAtStart.id,
          lastCached: cacheDate,
        };
        console.log('updateWSVariables :>> ', updateWSVariables);
        API.graphql(graphqlOperation(mutations.updateWorkspace, {
          input: updateWSVariables,
        }));
        let localBiosamples = Array.from(cachedBiosamplesMap.values());
        console.log('localBiosamples :>> ', localBiosamples);
        console.log('empty :>> ', empty);

        if (this.$store.state.addingNewBiosamples) {
          // Filter out all of the biosamples currently in the project so the user can't select them
          const localBiosamplesMap = new Map();
          this.$store.state.selectedProject.biosamples.items.forEach((bs) => {
            localBiosamplesMap.set(bs.bsshId, bs);
          });
          localBiosamples = localBiosamples.filter((bs) => {
            if (localBiosamplesMap.has(bs.id)) {
              return false;
            }
            return true;
          });
        }

        this.biosamples = localBiosamples;
        this.availableBiosamples = localBiosamples;
        this.filteredBiosamples = localBiosamples;
        this.availableProjects = await this.makeProjects();
        this.filteredProjects = this.availableProjects;
        if (!this.bsshFetchFailed) this.loadingBSSHData = false;
        console.log('---Done---');
      } catch (error) {
        console.error('Error in getAllBSSHBiosamples');
        console.error(error);
      }
    },
    async isBiosampleInCache(id, workspaceId) {
      const res = (await API.graphql(graphqlOperation(queries.getBSSHBiosample, {
        id,
        workspaceId,
      }))).data.getBSSHBiosample;
      console.log('res :>> ', res);
      if (res === null) {
        return {
          inCache: false,
          cachedBiosample: null,
        };
      }
      return {
        inCache: true,
        cachedBiosample: res,
      };
    },
    async updateBSSHBiosample(updateVariables) {
      try {
        await API.graphql(graphqlOperation(mutations
          .updateBSSHBiosample, {
          input: updateVariables,
        }));
      } catch (error) {
        console.error('Update bssh biosamples failed');
        console.error(error);
      }
    },
    async createBSSHBiosample(bsshBiosampleVariables) {
      try {
        await API.graphql(graphqlOperation(mutations
          .createBSSHBiosample, {
          input: bsshBiosampleVariables,
        }));
      } catch (error) {
        console.error('Create bssh biosamples failed');
        console.error(error);
      }
    },
    async deleteBSSHBiosample(deleteVariables) {
      try {
        await API.graphql(graphqlOperation(mutations.deleteBSSHBiosample, { input: deleteVariables }));
      } catch (error) {
        console.error('Delete bssh biosamples failed');
        console.error(error);
      }
    },
    async runBatchCalls(batch) {
      try {
        const promises = [];
        for (let i = 0; i < batch.length; i += 1) {
          const func = batch[i].func;
          const args = batch[i].args;
          promises.push(func(...args));
        }
        await Promise.all(promises);
      } catch (error) {
        console.error('Error in runBatchCalls');
        console.error(error);
      }
    },
    async getCachedBSSHBiosamples(workspaceAtStart) {
      try {
        const firstCacheBSCall = await API.graphql(graphqlOperation(customQueries.listBSSHBiosamplesSmall, {
          workspaceId: workspaceAtStart.id,
          limit: 5000,
          sortDirection: 'DESC',
        }));
        const localBiosamples = firstCacheBSCall.data.listBSSHBiosamples.items;
        let nextToken = firstCacheBSCall.data.listBSSHBiosamples.nextToken;
        while (nextToken !== null) {
          const nextCacheBSCall = await API.graphql(graphqlOperation(customQueries.listBSSHBiosamplesSmall, {
            workspaceId: workspaceAtStart.id,
            limit: 5000,
            sortDirection: 'DESC',
            nextToken,
          }));
          localBiosamples.push(...nextCacheBSCall.data.listBSSHBiosamples.items);
          nextToken = nextCacheBSCall.data.listBSSHBiosamples.nextToken;
        }
        if (workspaceAtStart !== this.workspace) {
          console.log('Workspace changed, aborting');
          return null;
        }
        const biosamplesMap = new Map();
        localBiosamples.forEach((bs) => {
          biosamplesMap.set(bs.id, bs);
        });
        return biosamplesMap;
      } catch (error) {
        console.error('Error in getCachedBSSHBiosamples');
        console.error(error);
        return null;
      }
    },
    exportCSV() {
      this.$refs.dt3.exportCSV();
    },
    async doneRenaming() {
      try {
        // Disabled for now
        // for (let i = 0; i < Object.values(this.badSamplesMap).length; i += 1) {
        //   const biosample = Object.values(this.badSamplesMap)[i];
        //   const newNameTest = this.illuminaStandardRegex.test(biosample.newName);
        //   if (!newNameTest) {
        //     this.$toast.add({
        //       severity: 'error',
        //       summary: 'Error',
        //       detail: 'There are still files that have an incorrect name!',
        //       life: 5000,
        //     });
        //     return;
        //   }
        // }
        let biosampleMap = {};
        if (!valueIsNullOrUndefined(this.filteredRenameBiosamples) && this.filteredRenameBiosamples.length > 0) {
          biosampleMap = this.makeBiosampleMapWithRenamedBiosamples(this.filteredRenameBiosamples);
        } else {
          biosampleMap = this.makeBiosampleMapWithRenamedBiosamples(this.badSamplesMap);
        }
        // eslint-disable-next-line consistent-return, array-callback-return
        const filteredForBothReads = Object.fromEntries(Object.entries(biosampleMap).filter(([key]) => {
          if (biosampleMap[key].fastqs.length >= 2) {
            return biosampleMap[key];
          }
        }));
        Object.assign(this.sampleToFastqPairs, filteredForBothReads);
        if (this.$store.state.addingNewBiosamples) {
          this.sampleToFastqPairs = Object.fromEntries(Object.entries(this.sampleToFastqPairs).filter((bs) => !this.$store.state.selectedProject.biosamples.items.some((projectBs) => projectBs.biosampleName === bs.sampleId)));
        }
        if (!valueIsNullOrUndefined(this.filteredRenameBiosamples) && this.filteredRenameBiosamples.length > 0) {
          this.filteredRenameBiosamples.forEach((formerlyBadSample) => {
            delete this.badSamplesMap[formerlyBadSample.sampleName];
          });
        } else {
          this.badSamplesMap = {};
        }
      } catch (error) {
        console.error(error);
      }
      this.renamingWindowOpen = false;
    },
    makeBiosampleMapWithRenamedBiosamples(badBiosamples) {
      const biosampleMap = {};
      // Object.values(this.badSamplesMap).forEach((badSampleObject) => {
      Object.values(badBiosamples).forEach((badSampleObject) => {
        const file = badSampleObject.newName;
        const match = file.match(this.illuminaStandardRegex);
        const biosampleName = match[1];
        if (biosampleName in biosampleMap) {
          if (!('fastq2' in biosampleMap[biosampleName])) {
            biosampleMap[biosampleName].fastq2 = badSampleObject.fullFilePath;
          }
          biosampleMap[biosampleName].fastqs.push(badSampleObject.fullFilePath);
          biosampleMap[biosampleName].fastqs_old_to_new_names.push({ old_fastq_name: badSampleObject.sampleName, new_fastq_name: badSampleObject.newName });
        } else {
          biosampleMap[biosampleName] = {
            sampleId: biosampleName,
            fastqs: [badSampleObject.fullFilePath],
            fastq1: badSampleObject.fullFilePath,
            fastqs_old_to_new_names: [{ old_fastq_name: badSampleObject.sampleName, new_fastq_name: badSampleObject.newName }],
          };
        }
      });
      return biosampleMap;
    },
    // illuminaStandardRegex: /(.*)(_S\d+_L\d+)/,
    attemptAutoRename() {
      try {
        if (!valueIsNullOrUndefined(this.filteredRenameBiosamples) && this.filteredRenameBiosamples.length > 0) {
          this.filteredRenameBiosamples.forEach((badSample) => {
            this.testAndReplaceInSample(badSample);
          });
        } else {
          Object.values(this.badSamplesMap).forEach((badSample) => {
            this.testAndReplaceInSample(badSample);
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    testAndReplaceInSample(badSample) {
      let newName = badSample.sampleName;
      if (!(/_S\d+_/.test(newName))) newName = newName.replace(/_S[a-zA-Z0-9]*_/, '_S1_');
      if (!(/_L\d+_/.test(newName))) newName = newName.replace(/_L[a-zA-Z0-9]*_/, '_L001_');
      if (!(/_R[12]_/.test(newName))) newName = newName.replace(/_R\d*_/, '_R1_');
      if (!(/_\d+\.fastq\.gz/.test(newName))) newName = newName.replace(/_[a-zA-Z0-9]+\.fastq\.gz/, '_001.fastq.gz');
      const finalRegexToSatisfy = /([A-Za-z0-9]+(-[A-Za-z0-9]+)+)_S1_L001_R[12]_001.fastq.gz/igm;
      // newName = this.removeBeforeExtension(newName);
      if (!(finalRegexToSatisfy.test(newName))) {
        // They still don't meet the required regex. Means the necessary S,L,R were never there
        newName = this.reconstructName(newName);
      }
      newName = newName.replace(/_+/g, '_');
      // eslint-disable-next-line no-param-reassign
      badSample.newName = newName;
    },
    reconstructName(newName) {
      let reconstructedName = '.fastq.gz';
      let split = newName.split('.fastq.gz');
      reconstructedName = `_001${reconstructedName}`;
      if (split[0].endsWith('_001')) split = split[0].split('_001');
      const endingChar = split[0].charAt(split[0].length - 1);
      reconstructedName = `_R${(isNumber(endingChar)) ? endingChar : '1'}${reconstructedName}`;
      split[0] += '_';
      if (split[0].endsWith('_R1_')) split = split[0].split('_R1_');
      if (split[0].endsWith('_R2_')) split = split[0].split('_R2_');
      split[0] += '_';
      reconstructedName = `_L001${reconstructedName}`;
      if (split[0].endsWith('_L001_')) split = split[0].split('_L001_');
      split[0] += '_';
      reconstructedName = `_S1${reconstructedName}`;
      if (split[0].endsWith('_S1_')) split = split[0].split('_S1_');
      reconstructedName = `${split[0]}${reconstructedName}`;
      reconstructedName = reconstructedName.replace(/_+/g, '_');
      return reconstructedName;
    },
    removeBeforeExtension(name) {
      const split = name.split(/_S\d+_L00\d+_R[12]_001/);
      const match = name.match(/_S\d+_L00\d+_R[12]_001/);
      return `${split[0]}${match}.fastq.gz`;
    },
    replaceMultipleDashes(str) {
      let newStr = '';
      for (let i = 0; i <= str.length; i += 1) {
        if (i === 0 || str.length || str.charAt(i) !== '_') newStr += str.charAt(i);
        // eslint-disable-next-line no-continue
        // if (str.charAt(i) === '_' && str.charAt(i + 1) === '_') continue;
      }
      return newStr;
    },
    onRenameTableFilter(event) {
      this.filteredRenameBiosamples = event.filteredValue;
    },
    noBiosamplesSelected() {
      try {
        if ((this.pullingBiosamplesFrom === 'BSSH' && (this.selectedBiosamples === null || this.selectedBiosamples === undefined || this.selectedBiosamples.length < 1)) || (this.pullingBiosamplesFrom === 'Shared Data' && (this.selectedS3Biosamples === null || this.selectedS3Biosamples === undefined || this.selectedS3Biosamples.length < 1))) {
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return true;
      }
    },
    toggleExpandedBiosampleTable() {
      this.expandedBiosampleSelectionOpened = !this.expandedBiosampleSelectionOpened;
      if (this.pullingBiosamplesFrom === 'BSSH') {
        this.toggleExpandedBSSHBiosampleTable();
      } else {
        this.toggleExpandedS3BiosampleTable();
      }
    },
    toggleExpandedBSSHBiosampleTable() {
      this.expandedBSSHBiosampleSelectionTable = !this.expandedBSSHBiosampleSelectionTable;
    },
    toggleExpandedS3BiosampleTable() {
      this.expandedS3BiosampleSelectionTable = !this.expandedS3BiosampleSelectionTable;
    },
    addAllBiosamplesForProjects() {
      // In case of adding biosamples to an already existing project, filter for those already in project
      if (this.$store.state.addingNewBiosamples) {
        let biosamples = [...new Set([...this.selectedBiosamples, ...this.filteredBiosamples])];
        const localBiosamplesMap = new Map();
        this.$store.state.selectedProject.biosamples.items.forEach((bs) => {
          localBiosamplesMap.set(bs.bsshId, bs);
        });
        biosamples = biosamples.filter((bs) => {
          if (localBiosamplesMap.has(bs.id)) {
            return false;
          }
          return true;
        });
        this.selectedBiosamples = removeDuplicatesById(biosamples);
        return;
      }
      this.selectedBiosamples = [...new Set([...this.selectedBiosamples, ...this.filteredBiosamples])];
      this.selectedBiosamples = removeDuplicatesById(this.selectedBiosamples);
      console.log('this.selectedBiosamples :>> ', this.selectedBiosamples);
    },
    async onUpload(event) {
      try {
        this.processingRenameFile = true;
        if (event.files === null || event.files === undefined) throw new Error('Event files is bad in upload');
        const file = event.files[0];
        if (!file.name.endsWith('.csv') && !file.name.endsWith('.tsv')) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Only .tsv and .csv files are accepted!',
            life: 5000,
          });
          return;
        }
        if (file === null || file === undefined) throw new Error('File is bad in upload');
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (evt) => {
          const rows = evt.target.result.split('\r');
          rows.forEach((row) => {
            if (row === '\n') return;
            let fixedRow = row;
            if (fixedRow.startsWith('\n')) fixedRow = fixedRow.split('\n')[1];
            let split = [];
            if (file.name.endsWith('.csv')) {
              split = fixedRow.split(',');
            } else if (file.name.endsWith('.tsv')) {
              split = fixedRow.split('\t');
            }
            const oldName = split[0];
            const newName = split[1];
            try {
              if (oldName in this.badSamplesMap) {
                this.badSamplesMap[oldName].newName = newName;
              } else {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'An original name has been changed. You must only change the new name.',
                  life: 5000,
                });
                throw new Error(' Original file name changed');
              }
            } catch (error) {
              console.error(error);
            }
          });
        };
        // eslint-disable-next-line no-unused-vars
        reader.onerror = (evt) => {
          console.error('Error reading file');
        };
      } catch (error) {
        console.error(error);
      }
      this.processingRenameFile = false;
    },
    // changeBadSampleMapValue(oldName, newName) {

    // },
    unpackLotId(lotId) {
      if (typeof (lotId) === 'object') return lotId.label;
      return lotId;
    },
    ImmediatePipelineLaunchShow() {
      this.$store.dispatch('setShowNewProject', false);
      this.$store.dispatch('setShowImmediatePipelineLaunch', true);
    },
    toggleLotIdImage() {
      this.showLotIdImage = !this.showLotIdImage;
    },
    openSettingBiosampleMetadataColumns() {
      this.settingBiosampleMetadataColumns = true;
    },
    onCellEditComplete(event) {
      this.badSamplesMap[event.data.sampleName].newName = event.newValue;
    },
    getHeader() {
      if (!this.creating) {
        if (this.$store.state.addingNewBiosamples) {
          return 'Add Biosamples';
        }
        if (this.renamingWindowOpen) {
          return 'Rename Files';
        }
        return 'Create Project';
      }
      return '';
    },
    validateString(string) {
      return validateString(string);
    },
    validateLotId(lotId) {
      return validateLotId(lotId);
    },
    clearAllSelectedBiosamples() {
      this.selectedBiosamples = [];
    },
    clearAllSelectedS3Biosamples() {
      this.selectedS3Biosamples = [];
    },
    resetVariables() {
      this.noBsshToken = false;
    },
    resetFilters() {
      this.bsshTableFilters = {
        bioSampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      };
      this.s3TableFilters = {
        bioSampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      };
      this.renameTableFilters = {
        sampleName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
      };
    },
  },
  computed: {
    getLotIds() {
      return constants.lotIds[this.$store.state.bioskrybProduct];
    },
    getGenomes() {
      return constants.genomes;
    },
    disableCreatingBecauseOfLoading() {
      if (this.pullingBiosamplesFrom === 'BSSH') {
        return this.loadingBSSHData;
      }
      return this.loadingS3Data;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingNewProject': async function () {
      this.showing = this.$store.state.showingNewProject;
      if (this.showing) {
        this.main();
      }
    },
    // This is a filter for the biosamples autocomplete. When a project is selected in the project autocomplete, filter through all of the biosamples and return those with the appropriate project name.
    selectedProjects(value) {
      if (value.length === 0) {
        this.availableBiosamples = this.biosamples;
      } else {
        let allBiosamples = [];
        value.forEach((project) => {
          const found = this.biosamples.filter((biosample) => biosample.defaultProject === project.name);
          allBiosamples.push(...found);
        });
        allBiosamples = removeDuplicatesById(allBiosamples);
        this.availableBiosamples = allBiosamples;
        this.filteredBiosamples = allBiosamples;
      }
    },
    projectName(value) {
      if (this.projectNameAllreadyExists === true && value !== '') {
        this.projectNameAllreadyExists = false;
      }
    },
    renameOnlyBadFiles() {
      if (this.renameOnlyBadFiles) {
        this.badSamplesMap = _.cloneDeep(this.badSamplesMapSaved);
      } else {
        this.badSamplesMapSaved = _.cloneDeep(this.badSamplesMap);
        this.addGoodSamplesForRenaming();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .p-radiobutton {
    margin-right: 10px;
  }

  .rb-label {
    position: relative;
    bottom: 3px;
  }

  .bjSpinner {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 40%;
    height: 40%;
  }

  .creating-spinner-wrapper {
    overflow: hidden;
  }

  // .p-dialog .p-dialog-content {
  //   padding: 0 1.5rem 0rem 1.5rem;
  // }

  .first-box {
    width: 49%;
    height: max-content;
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
    border-color: #dee2e6;
    margin-right: 10px;
  }

  .second-box {
    width: 48%;
    height: max-content;
    border-style: solid;
    border-width: 2px;
    border-radius: 8px;
    border-color: #dee2e6;
  }

  .box-text {
    padding: 10px;
  }

  .p-datatable .p-column-header-content {
    justify-content: center;
  }
  .correct-btn {
    margin-left: 10px;
    width: 50%;
  }

  ::v-deep(.p-column-filter-menu) {
    margin: 0px;
  }

  .bad {
    color: red;
  }

  .sample-name-notice {
    display: grid;
    justify-content: center;
    text-align: center;
  }

  .aut-rename-btn {
    width: 200px;
  }

  .rename-footer-options {
    display: flex;
    justify-content: space-between;
  }

  .processing-rename-spinner {
    width: 50px;
    height: 50px;
    margin-right: 35px;
    .bjSpinner {
      width: 100%;
      height: 100%;
    }
  }
  .export-btn {
    text-align: right;
    width: 150px;
  }

  ::v-deep(.p-datatable .p-datatable-header) {
    display: flex;
    justify-content: right;
  }

  ::v-deep(.lot-id-input) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

</style>
